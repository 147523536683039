<template>
  <div class="orderInfo">
    <div class="orderInfo__title">
      我的订单
      <span class="orderInfo__title__all" @click="getAllOrder"
        >全部&nbsp;></span
      >
    </div>
    <div class="box">
      <div class="box__item" @click="getAllOrder">
        <div class="iconfont">&#xe673;</div>
        <div class="box__title">待付款</div>
      </div>
      <div class="box__item" @click="getAllOrder">
        <div class="iconfont">&#xe67a;</div>
        <div class="box__title">待提货</div>
      </div>
      <div class="box__item" @click="getAllOrder">
        <div class="iconfont">&#xe661;</div>
        <div class="box__title">待评价</div>
      </div>
      <div class="box__item" @click="getAllOrder">
        <div class="iconfont">&#xe6ac;</div>
        <div class="box__title">已取消</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import router from '../../router'

const getAllOrder = async () => {
  router.push('OrderList')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.orderInfo {
  margin: 0rem 0.18rem 0.1rem 0.18rem;
  background: $bgColor;
  border-radius: 0.1rem;
  height: 1rem;
  &__title {
    color: $content-fontcolor;
    font-size: 0.16rem;
    padding: 0.14rem 0.16rem 0.05rem 0.16rem;
    &__all {
      color: $medium-fontColor;
      float: right;
      font-size: 0.14rem;
    }
  }
}
.box {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  color: $medium-fontColor;
  &__item {
    flex: 1;
    text-align: center;
    .iconfont {
      margin: 0.07rem 0 0.02rem 0;
      font-size: 0.25rem;
    }
  }
  &__title {
    font-size: 0.2rem;
    transform: scale(0.5, 0.5);
    transform-origin: center top;
  }
}
</style>
