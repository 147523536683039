<template>
  <div class="function" v-if="visible">
    <div class="function__title">常用功能</div>
    <div class="box">
      <div class="box__item">
        <div class="iconfont">&#xe653;</div>
        <div class="box__title" @click="GoodsManagePage()">管理商品</div>
      </div>
      <div class="box__item">
        <div class="iconfont">&#xe67d;</div>
        <div class="box__title" @click="AddGoodsPage(0)">新增商品</div>
      </div>
      <div class="box__item">
        <div class="iconfont">&#xe70c;</div>
        <div class="box__title" @click="UserManagePage()">用户管理</div>
      </div>
      <div class="box__item">
        <div class="iconfont">&#xe612;</div>
        <div class="box__title" @click="OrderManagePage()">订单管理</div>
      </div>
      <div class="box__item">
        <div class="iconfont">&#xe665;</div>
        <div class="box__title" @click="FinanceManagePage()">经营管理</div>
      </div>
      <div class="box__item">
        <div class="iconfont">&#xe64a;</div>
        <div class="box__title" @click="FinanceDataAnalysisPage()">
          财务分析
        </div>
      </div>
      <div class="box__item">
        <div class="iconfont">&#xe621;</div>
        <div class="box__title" @click="BatchUploadPage()">批量上传</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import store from '@/store'
import { ref } from 'vue'

const userInfo = store.getters.userInfo
const visible = ref(false)
userInfo.roles.forEach((element) => {
  if (element.id === 2 && element.name === 'Admin') visible.value = true
})
const router = useRouter()
const GoodsManagePage = () => {
  router.push({
    name: 'GoodsManage'
  })
}
const AddGoodsPage = (id) => {
  router.push(`/EditGoodsInfo/${id}`)
}
const UserManagePage = () => {
  router.push({
    name: 'AccountManage'
  })
}
const OrderManagePage = () => {
  router.push({
    name: 'OrderManage'
  })
}
const FinanceManagePage = () => {
  router.push({
    name: 'FinanceManage'
  })
}
const FinanceDataAnalysisPage = () => {
  router.push({
    name: 'FinanceDataAnalysis'
  })
}
const BatchUploadPage = () => {
  router.push({
    name: 'BatchUpload'
  })
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.function {
  margin: 0rem 0.18rem 0.1rem 0.18rem;
  background: $bgColor;
  border-radius: 0.1rem;
  min-height: 2rem;
  &__title {
    color: $content-fontcolor;
    font-size: 0.16rem;
    padding: 0.14rem 0.16rem 0.05rem 0.16rem;
  }
}
.box {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  color: $medium-fontColor;
  flex-wrap: wrap;
  &__item {
    width: 25%;
    text-align: center;
    .iconfont {
      margin: 0.07rem 0 0.02rem 0;
      font-size: 0.25rem;
    }
  }
  &__title {
    font-size: 0.2rem;
    transform: scale(0.5, 0.5);
    transform-origin: center top;
  }
}
</style>
