<template>
  <div class="navbar">
    <nut-navbar @on-click-back="backClick" title="更改用户信息"></nut-navbar>
  </div>
  <Form class="form" ref="target" v-slot="{ errors }">
    <div class="userInfo">
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">
          用户名<span class="character">*</span>
        </div>
        <div>
          <input
            class="userInfo__rows__value"
            disabled
            v-model="userInfo.username"
          />
        </div>
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">中文名</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="ChineseName"
            :rules="checkChineseName"
            v-model="userInfo.chineseName"
          />
        </div>
      </div>
      <div class="error" v-if="errors.ChineseName">
        {{ errors.ChineseName }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">英文名</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="EnglishName"
            :rules="checkEnglishName"
            v-model="userInfo.englishName"
          />
        </div>
      </div>
      <div class="error" v-if="errors.EnglishName">
        {{ errors.EnglishName }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">邮箱</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="userEmail"
            :rules="checkUserEmail"
            v-model="userInfo.email"
          />
        </div>
      </div>
      <div class="error" v-if="errors.userEmail">
        {{ errors.userEmail }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">微信</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="wechat"
            :rules="checkWechat"
            v-model="userInfo.wechat"
          />
        </div>
      </div>
      <div class="error" v-if="errors.wechat">
        {{ errors.wechat }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">角色</div>
        <div
          class="userInfo__rows__rolesCheckGroup"
          v-for="item of userInfo.roles"
          :key="item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">账号状态</div>
        <div class="userInfo__rows__rolesCheckGroup">
          <nut-switch v-model="isActive" disable />
        </div>
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">旧密码</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="oldPwd"
            :rules="checkPassword"
            v-model="passwordInfo.oldPassword"
            type="password"
          />
        </div>
      </div>
      <div class="error" v-if="errors.oldPwd">
        {{ errors.oldPwd }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">新密码</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="newPwd"
            :rules="checkPassword"
            v-model="passwordInfo.newPassword"
            type="password"
          />
        </div>
      </div>
      <div class="error" v-if="errors.newPwd">
        {{ errors.newPwd }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">确认密码</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="confirmPwd"
            :rules="checkPassword"
            v-model="passwordInfo.confirmPassword"
            type="password"
          />
        </div>
      </div>
      <div class="error" v-if="errors.confirmPwd">
        {{ errors.confirmPwd }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key"></div>
        <div>
          <nut-button
            type="info"
            color="#4fb0f9"
            block
            @click="handleUpdatePasswordClick"
            >修改密码</nut-button
          >
        </div>
      </div>
    </div>
  </Form>
  <div class="bottom">
    <nut-button type="info" color="#4fb0f9" block @click="handleSaveClick()"
      >保存</nut-button
    >
  </div>
  <nut-dialog
    :close-on-click-overlay="false"
    content="确认保存？"
    v-model:visible="visible"
    @ok="handleOk"
  ></nut-dialog>
</template>

<script setup>
import store from '@/store'
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { reqUserInfoUpdate, reqUpdatePassword } from '@/api'
import { Notify } from '@nutui/nutui'
import md5 from 'md5'
import { Field, Form } from 'vee-validate'
import { useFormValidate } from '@/utils/validate'

// 表单校验方法
const {
  checkChineseName,
  checkEnglishName,
  checkUserEmail,
  checkWechat,
  checkPassword
} = useFormValidate()

const userInfo = store.getters.userInfo
const target = ref(null)

const passwordInfo = reactive({
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
})
const isActive = ref(false)
if (userInfo.status === 1) {
  isActive.value = true
}

const resUserInfo = reactive({ user: {} })

// 获取用户角色
const userRoles = reactive([])
userInfo.roles.forEach((element) => {
  userRoles.push(element.name)
})
// 点击后退按钮
const router = useRouter()
const backClick = () => {
  router.push('/My/')
}
// 对话框弹出
const visible = ref(false)
const showDialog = () => {
  visible.value = true
}
// 保存账号信息
const changeUserInfo = async () => {
  try {
    userInfo.status = Number(isActive.value)
    resUserInfo.user.id = userInfo.id
    resUserInfo.user.username = userInfo.username
    resUserInfo.user.email = userInfo.email
    resUserInfo.user.chineseName = userInfo.chineseName
    resUserInfo.user.englishName = userInfo.englishName
    resUserInfo.user.wechat = userInfo.wechat
    await reqUserInfoUpdate(resUserInfo.user)
    Notify.success('保存成功', {
      duration: 1000,
      onClose() {
        router.push('/My/')
      }
    })
    await store.dispatch('user/getUserInfo')
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 点击保存按钮
const handleSaveClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    showDialog()
  }
}
// 对话框ok回调
const handleOk = () => {
  changeUserInfo()
}

// 修改密码
const handleUpdatePasswordClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    try {
      if (passwordInfo.oldPassword === '') {
        Notify.warn('请输入原始密码', {
          duration: 2000
        })
        return
      }
      if (passwordInfo.newPassword === '') {
        Notify.warn('请输入新密码', {
          duration: 2000
        })
        return
      }
      if (passwordInfo.confirmPassword === '') {
        Notify.warn('请输入确认密码', {
          duration: 2000
        })
        return
      }
      if (passwordInfo.newPassword !== passwordInfo.confirmPassword) {
        Notify.warn('新密码和旧密码不一致', {
          duration: 2000
        })
        return
      }
      await reqUpdatePassword({
        userId: userInfo.id,
        oldPassword: md5(passwordInfo.oldPassword),
        newPassword: md5(passwordInfo.newPassword)
      })
      Notify.success('修改密码成功', {
        duration: 1000
      })
    } catch (error) {
      Notify.danger(error, {
        duration: 3000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/customizedNutui.scss';

.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
}
.userInfo {
  margin-left: 0.25rem;
  margin-top: 0.9rem;
  &__rows {
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    &__key {
      font-weight: bold;
      font-size: 0.16rem;
      width: 25%;
    }
    &__value {
      display: block;
      width: 2.2rem;
      border: none;
      outline: none;
      background: $search-bgColor;
      height: 0.35rem;
      font-size: 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.05rem;
      padding-left: 0.1rem;
    }
    &__rolesCheckGroup {
      margin-left: 0.05rem;
    }
    &__roleCheckbox {
      margin-bottom: 0.05rem;
    }
  }
}
.bottom {
  position: absolute;
  margin-top: 0.3rem;
  width: 90%;
  background: $bgColor;
  padding: 0.1rem 5%;
  flex-direction: row;
  display: flex;
  align-items: center;
}
.error {
  font-size: 0.12rem;
  margin-left: 0.95rem;
  color: $hightlight-fontColor;
}
.character {
  color: $hightlight-fontColor;
}
</style>
