<template>
  <div class="wrapper">
    <div class="top">
      <div class="top__header">
        <div class="iconfont top__header__back" @click="handleBackClick">
          &#xe679;
        </div>
        请支付
      </div>

      <div class="top__orderInfo">
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">订单编号&nbsp;: </span>
          <span class="top__orderInfo__content">{{ orderInfo.orderNum }}</span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">创建时间&nbsp;: </span>
          <span class="top__orderInfo__content">
            {{ orderInfo.createDate }}
          </span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">支付方式&nbsp;: </span>
          <span class="top__orderInfo__content">
            {{
              // TODO: 临时代码
              orderInfo.payMethod == 1 ? '支付宝支付' : orderInfo.payMethod
            }}
          </span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">状态&nbsp;: </span>
          <span class="top__orderInfo__content">{{ orderInfo.status }}</span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">订单金额&nbsp;: </span>
          <span class="top__orderInfo__content amount">{{
            Number(orderInfo.payAmount).toFixed(2)
          }}</span>
        </div>
        <div
          class="top__orderInfo__item__payTime"
          v-if="orderInfo.status == '待支付'"
        >
          <span class="top__orderInfo__title">剩余支付时间&nbsp;:</span>
          <span class="top__orderInfo__content" v-if="data.orderCreateTime > 0">
            <nut-countdown
              :end-time="data.orderCreateTime"
              @on-end="handleCountDownEnd()"
            ></nut-countdown>
          </span>
        </div>
      </div>
    </div>
    <div class="payCode">
      <img :src="orderInfo.payCodeImg" class="payCode__img" />
    </div>
    <div class="pay" v-if="orderInfo.status == '待支付'">
      <div class="pay__cancel">
        <span @click="hanldCancleOrder">取消订单</span>
        <span @click="canclePay">取消支付</span>
      </div>
      <div class="pay__btn" @click="() => showConfirmChange(true)">
        支付完成
      </div>
    </div>
  </div>
  <div
    class="mask"
    v-show="showConfirm"
    @click="() => showConfirmChange(false)"
  >
    <div class="mask__content" @click.stop>
      <h3 class="mask__content__title">确认要离开收银台？</h3>
      <p class="mask__content__desc">请尽快完成支付，否则将被取消</p>
      <div class="mask__content__btns">
        <div
          class="mask__content__btn mask__content__btn--first"
          @click="canclePay"
        >
          取消支付
        </div>
        <div
          class="mask__content__btn mask__content__btn--last"
          @click="handleConfirmPay"
        >
          支付完成
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, onUnmounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  getOrderInfo,
  confirmPay,
  cancleOrder,
  reqGetCountDownTime
} from '@/api'
import { Notify } from '@nutui/nutui'

// 绑定数据
const data = reactive({ orderInfo: {}, orderCreateTime: -1 })
const route = useRoute()
let curPage = true
// 获取倒计时时间
const time = ref('')
const orderNum = ref('')
const getCountDownTime = async () => {
  const result = await reqGetCountDownTime({
    key: 'TimeInterval'
  })
  time.value = result.list[0].value
}
getCountDownTime()

const handleGetOrderInfo = async () => {
  const result = await getOrderInfo(route.params.id)
  result.orderInfo.createDate = result.orderInfo.createDate.replace(/(-)/g, '/')
  data.orderInfo = result.orderInfo
  data.orderCreateTime =
    Date.parse(data.orderInfo.createDate) + (time.value * 60 - 1) * 1000
  orderNum.value = result.orderInfo.orderNum
  if (result.orderInfo.status === '支付成功') {
    Notify.success('支付成功', {
      duration: 1000,
      onClose() {
        router.push(`/orderInfo/${orderNum.value}`)
      }
    })
  }
}
handleGetOrderInfo()
const { orderInfo } = toRefs(data)

onUnmounted(() => {
  curPage = false
})

// 倒计时结束回调函数
const handleCountDownEnd = () => {
  if (curPage) {
    //后台Job临时停掉，所以暂停跳转
    //router.push(`/orderInfo/${orderNum.value}`)
  }
}

// 确认支付蒙层
const showConfirm = ref(false)
const showConfirmChange = (status) => {
  showConfirm.value = status
}

// 确认支付
const handleConfirmPay = async () => {
  try {
    await confirmPay({
      orderId: orderInfo.value.orderId,
      payMethod: orderInfo.value.payMethod,
      payAmount: orderInfo.value.payAmount
    })
    // 跳转至订单详情页
    router.push(`/orderInfo/${orderInfo.value.orderNum}`)
  } catch (err) {
    Notify.danger(err, {
      duration: 3000
    })
    showConfirm.value = false
    // 提示下单失败
  }
}
// 取消订单
const hanldCancleOrder = async () => {
  try {
    await cancleOrder({
      orderId: orderInfo.value.orderId
    })

    Notify.success('取消成功', {
      duration: 1000,
      onClose() {
        // 跳转到订单详情页
        router.push(`/orderInfo/${orderInfo.value.orderNum}`)
      }
    })
  } catch (err) {
    Notify.danger(err, {
      duration: 3000
    })
  }
}

// 取消支付跳转到订单详情页
const canclePay = () => {
  router.push(`/orderInfo/${orderInfo.value.orderNum}`)
}

// 返回
const router = useRouter()
const handleBackClick = () => {
  router.back()
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #eee;
  overflow-y: scroll;
}
.top {
  position: relative;
  height: 2.5rem;
  background-color: 100% 1.59rem;
  background-image: linear-gradient(0deg, rgba(0, 145, 255, 0) 4%, #0091ff 50%);
  background-repeat: no-repeat;
  &__header {
    position: relative;
    padding-top: 0.26rem;
    line-height: 0.24rem;
    color: $bgColor;
    text-align: center;
    font-size: 0.16rem;
    &__back {
      position: absolute;
      left: 0.18rem;
      font-size: 0.22rem;
    }
  }
  &__orderInfo {
    position: absolute;
    left: 0.18rem;
    right: 0.18rem;
    top: 0.68rem;
    height: 1.2rem;
    background: $bgColor;
    border-radius: 0.1rem;
    padding: 0.1rem 0.16rem 0.14rem 0.16rem;
    &__item {
      line-height: 0.16rem;
      padding: 0.032rem 0;
      font-size: 0.14rem;
      color: $medium-fontColor;
      .amount {
        color: green;
        font-size: 0.18rem;
      }
      &__payTime {
        display: flex;
        color: red;
        font-size: 0.14rem;
      }
    }
    &__title {
      margin-right: 0.05rem;
    }
    &__content {
      font-weight: bold;
    }
  }
}
.payCode {
  margin: 0 auto;
  text-align: center;
  &__img {
    width: 3rem;
    height: 3rem;
  }
}

.pay {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  height: 0.49rem;
  line-height: 0.49rem;
  background: $bgColor;
  &__cancel {
    flex: 1;
    text-indent: 0.24rem;
    font-size: 0.14rem;
    color: $btn-bgColor;
    span {
      margin-right: 0.2rem;
    }
  }
  &__btn {
    width: 0.98rem;
    background: $btn-bgColor;
    color: #fff;
    text-align: center;
    font-size: 0.14rem;
  }
}

.mask {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3rem;
    height: 1.56rem;
    background: #fff;
    text-align: center;
    border-radius: 0.04rem;
    &__title {
      margin: 0.24rem 0 0 0;
      line-height: 0.26rem;
      font-size: 0.18rem;
      color: #333;
    }
    &__desc {
      margin: 0.08rem 0 0 0;
      font-size: 0.14rem;
      color: #666666;
    }
    &__btns {
      display: flex;
      margin: 0.24rem 0.58rem;
    }
    &__btn {
      flex: 1;
      width: 0.8rem;
      line-height: 0.32rem;
      border-radius: 0.16rem;
      font-size: 0.14rem;
      &--first {
        margin-right: 0.12rem;
        border: 0.01rem solid #4fb0f9;
        color: #4fb0f9;
      }
      &--last {
        margin-left: 0.12rem;
        background: #4fb0f9;
        color: #fff;
      }
    }
  }
}
</style>
