<template>
  <div class="wrapper">
    <div class="top">
      <div class="top__header">
        <div class="iconfont top__header__back" @click="handleBackClick">
          &#xe679;
        </div>
        订单详情
      </div>
      <div class="top__orderInfo">
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">订单编号&nbsp;: </span>
          <span class="top__orderInfo__content">{{ orderInfo.orderNum }}</span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">交易编号&nbsp;: </span>
          <span class="top__orderInfo__content">{{
            orderInfo.transactionNum
          }}</span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">创建时间&nbsp;: </span>
          <span class="top__orderInfo__content">{{
            orderInfo.createDate
          }}</span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">支付时间&nbsp;: </span>
          <span class="top__orderInfo__content">{{
            orderInfo.payTime == '' ? '无' : orderInfo.payTime
          }}</span>
        </div>

        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">支付金额&nbsp;: </span>
          <span class="top__orderInfo__content">
            {{ Number(orderInfo.payAmount).toFixed(2) }}
          </span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">支付方式&nbsp;: </span>
          <span class="top__orderInfo__content">{{
            // TODO: 临时代码
            orderInfo.payMethod == 1 ? '支付宝支付' : orderInfo.payMethod
          }}</span>
        </div>
        <div class="top__orderInfo__item">
          <span class="top__orderInfo__title">状态&nbsp;: </span>
          <span class="top__orderInfo__content">{{ orderInfo.status }}</span>
        </div>
        <div class="top__orderInfo__item" v-if="orderInfo.status == '待支付'">
          <div class="top__orderInfo__item__payTime">
            <span class="top__orderInfo__title">剩余支付时间&nbsp;:</span>
            <span
              class="top__orderInfo__content"
              v-if="data.orderCreateTime > 0"
            >
              <nut-countdown :end-time="data.orderCreateTime"></nut-countdown>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="products">
      <div class="products__title">
        <b> {{ SHOPNAME }} </b>
      </div>
      <div class="products__wrapper">
        <div class="products__list">
          <div v-for="item in goods" :key="item._id" class="products__item">
            <img class="products__item__img" :src="item.goodsImg" />
            <div class="products__item__detail">
              <h4 class="products__item__title">{{ item.goodsName }}</h4>
              <p class="products__item__price">
                <span>
                  <span class="products__item__yen">&yen; </span>
                  {{ item.unitPrice.toFixed(2) }} x {{ item.orderSales }}
                </span>
                <span class="products__item__total">
                  <span class="products__item__yen">&yen; </span>
                  {{ (item.unitPrice * item.orderSales).toFixed(2) }}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="products__choose" v-if="orderInfo.status == '待支付'">
          <div class="products__choose__cancelOrder" @click="hanldCancleOrder">
            取消订单
          </div>
          <div class="products__choose__payOrder" @click="payOrder">去支付</div>
        </div>
      </div>
    </div>
  </div>
  <Docker :currentIndex="2" dockerName="OrderList" />
</template>

<script setup>
import { reactive, ref, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { getOrderInfo, cancleOrder, reqGetCountDownTime } from '@/api'
import { SHOPNAME } from '@/constant'
import Docker from '@/components/Docker'
import { Notify } from '@nutui/nutui'

// 绑定数据
const data = reactive({
  goods: [],
  orderInfo: {},
  orderCreateTime: -1
})
const route = useRoute()

// 获取倒计时时间
const time = ref('')
const getCountDownTime = async () => {
  const result = await reqGetCountDownTime({
    key: 'TimeInterval'
  })
  time.value = result.list[0].value
}
getCountDownTime()

const handleGetOrderInfo = async () => {
  const result = await getOrderInfo(route.params.id)
  result.orderInfo.createDate = result.orderInfo.createDate.replace(/(-)/g, '/')
  data.goods = result.goods
  data.orderInfo = result.orderInfo
  data.orderCreateTime =
    Date.parse(data.orderInfo.createDate) + (time.value * 60 - 1) * 1000
}
handleGetOrderInfo()

const { goods, orderInfo } = toRefs(data)

// 取消订单
const hanldCancleOrder = async () => {
  try {
    await cancleOrder({
      orderId: orderInfo.value.orderId
    })
    location.reload()
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}

const payOrder = async () => {
  const result = await getOrderInfo(route.params.id)
  const orderNum = result.orderInfo.orderNum
  router.push(`/Pay/${orderNum}`)
}

// 返回
const router = useRouter()
const handleBackClick = () => {
  router.push('/OrderList')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #eee;
  overflow-y: scroll;
}
.top {
  position: relative;
  height: 2.7rem;
  background-color: 100% 1.4rem;
  background-image: linear-gradient(0deg, rgba(0, 145, 255, 0) 4%, #0091ff 40%);
  background-repeat: no-repeat;
  &__header {
    position: relative;
    padding-top: 0.26rem;
    line-height: 0.24rem;
    color: $bgColor;
    text-align: center;
    font-size: 0.16rem;
    &__back {
      position: absolute;
      left: 0.18rem;
      font-size: 0.22rem;
    }
  }
  &__orderInfo {
    position: absolute;
    left: 0.18rem;
    right: 0.18rem;
    top: 0.68rem;
    height: 1.65rem;
    background: $bgColor;
    border-radius: 0.1rem;
    padding: 0.1rem 0.16rem 0.14rem 0.16rem;
    &__item {
      line-height: 0.16rem;
      padding: 0.032rem 0;
      font-size: 0.14rem;
      color: $medium-fontColor;
      &__payTime {
        display: flex;
        color: red;
      }
    }
    &__title {
      margin-right: 0.05rem;
    }
    &__content {
      font-weight: bold;
    }
  }
}
.products {
  margin: 0rem 0.18rem 0.1rem 0.18rem;
  background: $bgColor;
  border-radius: 0.1rem 0.1rem 0 0;
  &__title {
    padding: 0.16rem;
    font-size: 0.16rem;
    color: $dark-fontColor;
  }
  &__wrapper {
    overflow-y: scroll;
    margin: 0 0.18rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.6rem;
    top: 3.2rem;
  }
  &__list {
    background: $bgColor;
    border-radius: 0 0 0.1rem 0.1rem;
  }
  &__item {
    position: relative;
    display: flex;
    padding: 0 0.16rem 0.16rem 0.16rem;
    &__img {
      width: 0.46rem;
      height: 0.46rem;
      margin-right: 0.16rem;
    }
    &__detail {
      flex: 1;
    }
    &__title {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      @include ellipsis;
    }
    &__price {
      display: flex;
      margin: 0.06rem 0 0 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $hightlight-fontColor;
    }
    &__total {
      flex: 1;
      text-align: right;
      color: $dark-fontColor;
    }
    &__yen {
      font-size: 0.12rem;
    }
  }
  &__choose {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-around;
    height: 0.39rem;
    border-top: 0.01rem solid $content-bgColor;
    line-height: 0.39rem;
    &__cancelOrder {
      width: 0.98rem;
      background-color: $light-fontColor;
      color: $bgColor;
      text-align: center;
      font-size: 0.14rem;
    }
    &__payOrder {
      width: 0.98rem;
      text-align: center;
      font-size: 0.14rem;
      background-color: $btn-bgColor;
      color: $bgColor;
    }
  }
}
</style>
