//TODO: 页面样式提取，删掉无用代码，优化编码方式
<template>
  <div>
    <nut-navbar @on-click-back="backClick" title="商品详情"></nut-navbar>
    <div>
      <nut-swiper
        v-if="ImgList.length > 0"
        :pagination-visible="true"
        pagination-color="#426543"
        height="180"
      >
        <nut-swiper-item v-for="p in ImgList" :key="p.id">
          <img
            :src="p.url"
            style="width: 100%; height: 100%; object-fit: contain"
          />
        </nut-swiper-item>
      </nut-swiper>

      <div class="goods">
        <div class="goodsInfo">
          <div class="goodsInfo__name">{{ goods.goodsName }}</div>
          <div class="goodsInfo__used">库存: {{ goods.residue }}</div>
          <div class="goodsInfo__price">
            <span class="goodsInfo__price__rnb">￥</span>
            {{ Number(goods.unitPrice).toFixed(2) }}
          </div>
        </div>
        <div class="addToCart">
          <nut-button
            v-if="goods.residue !== 0"
            size="small"
            color="#4fb0f9"
            @click="addToCartFromDetail"
            >加入购物车</nut-button
          >
          <nut-button
            v-if="goods.residue === 0"
            size="small"
            color="#777"
            disabled
            >加入购物车</nut-button
          >
        </div>
      </div>
      <div class="feedBack">评论区</div>

      <Cart />
    </div>
  </div>
</template>

<script setup>
import { reactive, toRefs, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Cart from './Cart'
import { useCommonCartEffect } from '@/effects/cartEffects'
import { getGoodsInfo } from '@/api'
import { Notify } from '@nutui/nutui'

const route = useRoute()
const router = useRouter()
const goodsId = route.params.id

const data = reactive({
  goods: {}
})
const ImgList = reactive([])
const { goods } = toRefs(data)

onMounted(async () => {
  const result = await getGoodsInfo({
    goodsId: goodsId
  })
  data.goods = result
  if (result.goodsImgList.length !== 0) {
    result.goodsImgList.forEach((element) => {
      ImgList.push({
        id: element.id,
        isTop: element.isTop,
        sort: element.sort,
        url: element.src
      })
    })
  } else {
    ImgList.push({
      id: 1,
      url: '/images/defaultPic.png'
    })
  }
})

// 回退事件逻辑
const backClick = () => {
  router.push('/shop')
}
const { changeCartItemInfo, isLimit } = useCommonCartEffect()
const addToCartFromDetail = () => {
  if (!isLimit(goodsId) && goods.value.residue > 0) {
    changeCartItemInfo(goodsId, goods.value, 1)
  } else {
    Notify.danger('上限啦，不能再加了', {
      duration: 3000
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.goods {
  background: $search-bgColor;
  margin: 0.05rem 0.05rem;
  padding: 0.05rem 0.05rem;
  border-radius: 0.1rem;
}
.goodsInfo {
  margin-top: 0.08rem;
  margin-left: 0.08rem;
  &__name {
    font-weight: bold;
    font-size: 0.17rem;
  }
  &__price {
    font-weight: bold;
    color: #ff4400;
    font: arial;
    font-size: 0.25rem;
    line-height: 0.25rem;
    margin-top: 0.05rem;
    margin-top: 0.08rem;
    &__rnb {
      font-size: 0.19rem;
    }
  }

  &__used {
    color: #4f4f4f;
    font-size: 0.14rem;
    line-height: 0.25rem;
    margin-top: 0.08rem;
  }
}
.addToCart {
  position: absolute;
  right: 0.15rem;
  top: 3.45rem;
}
.feedBack {
  background: $search-bgColor;
  margin: 0.1rem 0.05rem;
  padding: 0.05rem 0.05rem;
  border-radius: 0.1rem;
  height: 2.2rem;
}
</style>
