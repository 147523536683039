<template>
  <div class="top">
    <div class="top__header">
      <div class="iconfont top__header__back" @click="handleBackClick">
        &#xe679;
      </div>
      确认订单
    </div>
    <div class="top__paymethod">
      <div class="top__paymethod__title" @click="handleMaskShowChange">
        <span><b>支付方式</b></span>
        <span class="iconfont top__paymethod__rightIcon"> &#xe639; </span>
        <span class="top__paymethod__right">{{ currPayMethod }}</span>
      </div>
    </div>
  </div>

  <div class="mask" v-if="showMask" @click="handleMaskShowChange" />
  <div class="paymethod" v-if="showMask">
    <div class="header">
      支付方式
      <div class="iconfont header__close" @click="handleCloseClick">
        &#xe6e9;
      </div>
    </div>
    <div class="content">
      <div class="content__item" v-for="item in payMethodList" :key="item.name">
        <span
          :class="item.name"
          class="iconfont content__item__icon"
          v-html="item.icon"
        ></span>
        <span>{{ item.text }}</span>
        <span>
          <input
            type="radio"
            class="content__item__radio"
            name="rdPayMethod"
            :value="item.name"
            :checked="ifchecked(item.text)"
            @click="handleChoosePaymethod(item)"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { setItem } from '@/utils/storage'
import { PAYMETHOD } from '@/constant'

const payMethodList = [
  { name: 'aliPay', text: '支付宝支付', icon: '&#xe88c;', isDefault: true }
  // { name: 'wechatPay', text: '微信支付', icon: '&#xe600;', isDefault: false }
]
const currPayMethod = ref('')

const router = useRouter()
const handleBackClick = () => {
  router.back()
}

// 展示支付方式
const showMask = ref(false)
const handleMaskShowChange = () => {
  showMask.value = !showMask.value
}

const ifchecked = (prop) => {
  return prop === currPayMethod.value
}

// 关闭
const handleCloseClick = () => {
  showMask.value = false
}

const handleChoosePaymethod = (item) => {
  setItem(PAYMETHOD, item.name)
  currPayMethod.value = item.text
  setTimeout(() => {
    handleCloseClick()
  }, 300)
}

handleChoosePaymethod(payMethodList.filter((p) => p.isDefault)[0])
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.top {
  position: relative;
  height: 1.4rem;
  background-color: 100% 1.59rem;
  background-image: linear-gradient(0deg, rgba(0, 145, 255, 0) 4%, #0091ff 50%);
  background-repeat: no-repeat;
  &__header {
    position: relative;
    padding-top: 0.26rem;
    line-height: 0.24rem;
    color: $bgColor;
    text-align: center;
    font-size: 0.16rem;
    &__back {
      position: absolute;
      left: 0.18rem;
      font-size: 0.22rem;
    }
  }
  &__paymethod {
    position: absolute;
    left: 0.18rem;
    right: 0.18rem;
    top: 0.75rem;
    height: 0.5rem;
    background: $bgColor;
    border-radius: 0.04rem;
    &__title {
      line-height: 0.22rem;
      padding: 0.16rem 0.16rem 0.14rem 0.16rem;
      font-size: 0.16rem;
      color: $dark-fontColor;
    }
    &__right {
      position: relative;
      float: right;
    }
    &__rightIcon {
      position: relative;
      float: right;
      margin-left: 0.02rem;
    }
    &__icon {
      transform: rotate(180deg);
      position: absolute;
      right: 0.16rem;
      top: 0.5rem;
      color: $medium-fontColor;
      font-size: 0.2rem;
    }
  }
}
.mask {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}
.paymethod {
  height: 2rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: $bgColor;
  border-radius: 0.16rem 0.16rem 0 0;
}
.header {
  position: relative;
  padding: 0.12rem 0.16rem 0.12rem 0.16rem;
  line-height: 0.4rem;
  font-size: 0.18rem;
  color: $dark-fontColor;
  text-align: center;
  &__close {
    position: absolute;
    right: 0.12rem;
    top: 0.12rem;
    font-size: 0.16rem;
  }
}
.content {
  &__item {
    border-top: 1px solid $light-fontColor;
    height: 0.65rem;
    line-height: 0.65rem;
    font-size: 0.16rem;
    color: $content-fontcolor;
    padding: 0 0.16rem 0 0.45rem;
    position: relative;
    &__icon {
      margin-right: 0.06rem;
      font-size: 0.25rem;
      line-height: 0.5rem;
      position: absolute;
      top: 0.06rem;
      left: 0.12rem;
    }
    &__radio {
      position: absolute;
      top: 0.26rem;
      right: 0.3rem;
    }
  }
  .aliPay {
    color: #1678ff;
  }
  .wechatPay {
    color: #04be02;
  }
}
</style>
