<template>
  <div class="products">
    <div class="products__title">
      <b> {{ SHOPNAME }} </b>
    </div>
    <div class="products__wrapper">
      <div class="products__list">
        <div
          v-for="item in productCheckedList"
          :key="item._id"
          class="products__item"
        >
          <img class="products__item__img" :src="item.goodsImg" />
          <div class="products__item__detail">
            <h4 class="products__item__title">{{ item.goodsName }}</h4>
            <p class="products__item__price">
              <span>
                <span class="products__item__yen">&yen; </span>
                {{ item.unitPrice.toFixed(2) }} x {{ item.count }}
              </span>
              <span class="products__item__total">
                <span class="products__item__yen">&yen; </span>
                {{ (item.unitPrice * item.count).toFixed(2) }}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCommonCartEffect } from '@/effects/cartEffects'
import { SHOPNAME } from '@/constant'
const { productCheckedList } = useCommonCartEffect()
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
.products {
  margin: 0rem 0.18rem 0.1rem 0.18rem;
  background: $bgColor;
  border-radius: 0.04rem 0.04rem 0 0;
  &__title {
    padding: 0.16rem;
    font-size: 0.16rem;
    color: $dark-fontColor;
  }
  &__wrapper {
    overflow-y: scroll;
    margin: 0 0.18rem;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0.6rem;
    top: 1.9rem; // 1.26
  }
  &__list {
    background: $bgColor;
    border-radius: 0 0 0.04rem 0.04rem;
  }
  &__item {
    position: relative;
    display: flex;
    padding: 0 0.16rem 0.16rem 0.16rem;
    &__img {
      width: 0.46rem;
      height: 0.46rem;
      margin-right: 0.16rem;
    }
    &__detail {
      flex: 1;
    }
    &__title {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      @include ellipsis;
    }
    &__price {
      display: flex;
      margin: 0.06rem 0 0 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $hightlight-fontColor;
    }
    &__total {
      flex: 1;
      text-align: right;
      color: $dark-fontColor;
    }
    &__yen {
      font-size: 0.12rem;
    }
  }
}
</style>
