<template>
  <div class="wrapper">
    <UserInfo />
    <OrderInfo />
    <Functions />
    <div class="logout" @click="logoutClick">退出登录</div>
  </div>
  <Docker :currentIndex="3" dockerName="My" />
</template>

<script setup>
import { useStore } from 'vuex'
import UserInfo from './UserInfo'
import OrderInfo from './OrderInfo'
import Functions from './Functions'
import Docker from '@/components/Docker'

const store = useStore()
const logoutClick = () => {
  store.dispatch('user/userLogout')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0.4rem;
  background-color: #eee;
  overflow-y: scroll;
}
.logout {
  text-align: center;
  line-height: 0.5rem;
  padding-bottom: 0.15rem;
  font-size: 0.16rem;
  color: $content-fontcolor;
}
</style>
