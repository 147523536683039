<template>
  <div class="top">
    <div class="userImg top__userImg">
      <nut-uploader
        :url="`/api/account/uploadAvatar`"
        :headers="headers"
        :maximize="1024 * 500"
        @success="handleUpload"
        @oversize="onOversize"
        :clear-input="true"
        :maximum="999"
      >
        <img :src="data.AvatarSrc" />
      </nut-uploader>
    </div>

    <div class="top__userInfo">
      <div class="top__userInfo__text">{{ userInfo.chineseName }}</div>
      <div class="top__userInfo__text">Email: {{ userInfo.email }}</div>
      <div class="top__userInfo__text">英文名: {{ userInfo.englishName }}</div>
      <div class="top__userInfo__text">微信号: {{ userInfo.wechat }}</div>
      <div class="top__userInfo__edit iconfont" @click="goToUserInfoUpdate">
        &#xe667;
      </div>
    </div>
  </div>
</template>

<script setup>
import store from '@/store'
import { useRouter } from 'vue-router'
import { reactive } from '@vue/reactivity'
import { Notify } from '@nutui/nutui'
import { useStore } from 'vuex'

const userInfo = store.getters.userInfo
const userHeader = store.getters.userInfo.userSrc

const headers = reactive({
  'Access-Control-Allow-Origin': '*',
  Authorization: store.getters.token
})

const router = useRouter()
const getUserStore = useStore()
const getUserInfo = () => {
  getUserStore.dispatch('user/getUserInfo')
}
const data = reactive({ AvatarSrc: userHeader })

// 进入用户信息编辑页面
const goToUserInfoUpdate = () => {
  router.push('/My/updateProfile')
}
// 上传头像回调
const handleUpload = async (res) => {
  const resTxt = res.responseText
  const resJson = JSON.parse(resTxt)
  try {
    if (resJson.code === 200) {
      data.AvatarSrc = resJson.data.src
      await getUserInfo()
      Notify.success('上传成功', {
        duration: 1000
      })
    } else {
      Notify.danger('上传失败，请重新上传', {
        duration: 3000
      })
    }
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 头像尺寸超过500k回调
const onOversize = async (files) => {
  Notify.warn('图片大小不能超过 500 kb', {
    duration: 3000
  })
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
.top {
  position: relative;
  height: 2.7rem;
  background-color: 100% 1.4rem;
  background-image: linear-gradient(0deg, rgba(0, 145, 255, 0) 4%, #0091ff 40%);
  background-repeat: no-repeat;
  &__userInfo {
    position: absolute;
    left: 0.18rem;
    right: 0.18rem;
    top: 0.8rem;
    height: 0.8rem;
    background: $bgColor;
    border-radius: 0.1rem;
    padding: 0.66rem 0.16rem 0.14rem 0.16rem;
    &__text {
      font-size: 0.14rem;
      line-height: 0.2rem;
    }
    &__edit {
      position: absolute;
      bottom: 0.02rem;
      right: 0.08rem;
      color: $medium-fontColor;
    }
    .iconfont {
      margin: 0.07rem 0 0.02rem 0;
      font-size: 0.25rem;
    }
  }
  &__userImg {
    position: absolute;
    top: 0.3rem;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
  }
  &__userImgSet {
    position: absolute;
    top: 0.68rem;
    bottom: 0;
    left: 0.73rem;
    right: 0;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    z-index: 2;
    &__iconfont {
      margin: 0.07rem 0 0.02rem 0;
      font-size: 0.25rem;
    }
  }
  .userImg {
    border-radius: 0.5rem;
    width: 1rem;
    height: 1rem;
    // background: url('/images/userHeader.png') no-repeat center;
    // background-size: 1rem;
  }
  img {
    border-radius: 0.5rem;
    width: 1rem;
    height: 1rem;
    // background: url('/images/userHeader.png') no-repeat center;
    // background-size: 1rem;
  }
}
</style>
